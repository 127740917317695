import React from 'react';

import { EmailSequencesContainer, FinalEmailReviewWrapper, ReviewEmailsContainer } from './final-email-review.styles';

import ReviewEmailEditor from '../../../modules/review-email-editor/components/review-email-editor.component';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { companyForReviewSelector } from '../../../entities/company-target-group/store/company-target-group.selectors';
import {
  ICompanyTargetGroupDataForReview,
  PipelineStage,
} from '../../../entities/company-target-group/domain/company-target-group.types';
import SelectSequenceLeadWithDropdown from '../../select-sequence-lead-with-dropdown/components/select-sequence-lead-with-dropdown.component';

function EmailSequences({ companyForReview }: { companyForReview: ICompanyTargetGroupDataForReview }) {
  const { emailSendStatuses, pipelineStage } = companyForReview;

  return emailSendStatuses
    .map(({ id, emailStatuses, emailsIndexes, contactedLeadsIds, leadId, emails }: any) => {
      const isContacted = Boolean(emailStatuses[0]); // INFO: A contacted email has a date string value, evaluating to "true"; an uncontacted email is "null," hence evaluates to "false".
      const isCurrentStageReviwed = pipelineStage === PipelineStage.REVIEWED;

      if (isCurrentStageReviwed && !isContacted) return; // INFO: The uncontacted emails are hidden for on "reviewed" stage

      return (
        <EmailSequencesContainer>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginTop: '15px',
              marginBottom: '-10px',
            }}
          >
            <h3>{`Sequence ${id}`}</h3>

            <SelectSequenceLeadWithDropdown
              sequenceIndex={emailsIndexes[0]}
              contactedLeadsIds={contactedLeadsIds}
              currentLeadId={leadId}
              isContacted={isContacted}
            />
          </div>
          <ReviewEmailEditor
            indexInSequence={emailsIndexes[0]}
            label="Email 1"
            emailStatus={emailStatuses[0]}
            historyEmail={emails[0]}
          />
          <ReviewEmailEditor
            indexInSequence={emailsIndexes[1]}
            label="Email 2"
            emailStatus={emailStatuses[1]}
            historyEmail={emails[1]}
          />
        </EmailSequencesContainer>
      );
    })
    .filter(Boolean);
}

function FinalEmailReview() {
  const companyForReview = useAppSelector(companyForReviewSelector).item;

  return (
    <FinalEmailReviewWrapper>
      <ReviewEmailsContainer>
        <EmailSequences companyForReview={companyForReview} />
      </ReviewEmailsContainer>
    </FinalEmailReviewWrapper>
  );
}

export default FinalEmailReview;
