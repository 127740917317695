import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const DashboardLayoutContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.background.black,
  color: theme.palette.common.white,

  '&.dashboard-layout-container': {
    padding: '0 1.2%',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const DashboardLayoutBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  backgroundColor: theme.palette.background.black,
  boxShadow: 'none',
  padding: '30px 3% 20px',
  position: 'static',
}));

export const LogoImage = styled('img')(() => ({
  width: '68px',
  height: '40px',
}));
