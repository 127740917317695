import Card, { CardProps } from '@mui/material/Card';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const AuthFormSectionContainer = styled(Card)<CardProps>(({ theme }) => ({
  width: '40%',
  minWidth: '400px',
  minHeight: '550px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.blue,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '16px',
  padding: '35px 50px 80px',
  overflow: 'visible',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
    width: '30%',
  },
  [theme.breakpoints.up(2000)]: {
    maxWidth: '100%',
    width: '25%',
  },
}));

export const LogoImage = styled('img')(() => ({
  position: 'absolute',
  top: '-60px',
  left: 0,
  width: '68px',
  height: '40px',
}));

export const AuthFormSectionHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: '90px',
  fontSize: '1.5rem',
  fontWeight: 600,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.75rem',
  },
}));
